import React from 'react'
import Header from './main/Header'
import Footer from './main/Footer'
import { Link } from 'react-router-dom'

export default function Home() {
    return (
        <>
            <Header />
            {/* banner section */}
            <section id="home" className="w3l-banner area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </section>

            {/* About Section */}
            <section className="w3l-about py-5" id="about">
                <div className="container py-md-5 py-2">
                    <div className="row w3l-row align-items-center">
                        <div className="col-lg-6 w3l-image pe-lg-5 mb-lg-0 mb-md-5 mb-4">
                            <img src="assets/img/security2.png" alt="" className="img-fluid vert-move" width={450} />
                        </div>
                        <div className="col-lg-6 w3l-about-info px-lg-4 text-lg-start text-center">
                            <h6 className="w3l-title-small mb-1">About Us</h6>
                            <h3 className="w3l-title-main mb-2">
                                We provide the best IT Services
                            </h3>
                            <p className="mt-2 pe-lg-5">
                                At ARJ Technologies, we provide top-notch IT services to help businesses succeed in the digital era. Our expertise covers custom software, web and mobile app development, design, hosting, cloud solutions, and cybersecurity. We also offer IT consulting to support your technological needs.
                            </p>
                            <div className="w3l-btn">
                                <Link
                                    to={"/About"}
                                    className="btn btn-style btn-secondary mt-lg-3 mt-0 me-2"
                                >
                                    Read More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* //About Section*/}
            {/* Services Section */}
            <section className="about-section py-5">
                <div className="container py-lg-5 py-md-4 py-2">
                    <div className="title-heading-w3 mx-auto text-center mb-sm-5 mb-4 pb-xl-4">
                        <h3 className="w3l-title-main">
                            We run all kinds of IT services that vow your success
                        </h3>
                    </div>
                    <div className="row justify-content-center">
                        {/* First Card */}
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/icon-3d-11.webp" alt="" className="w-100" />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Soft. Development</a>
                                    </h5>
                                    <button className="btn btn-primary rounded-pill">Read More</button>
                                </div>
                            </div>
                        </div>

                        {/* Second Card */}
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/icon-3d-4.webp" alt="" className="w-100" />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Web Development</a>
                                    </h5>
                                    <button className="btn btn-primary rounded-pill">Read More</button>
                                </div>
                            </div>
                        </div>

                        {/* Third Card */}
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/icon-3d-5.webp" alt="" className="w-100" />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">App Development</a>
                                    </h5>
                                    <button className="btn btn-primary rounded-pill">Read More</button>
                                </div>
                            </div>
                        </div>

                        {/* Fourth Card */}
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/desgin1.png" alt="" className="w-100" />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Web Design</a>
                                    </h5>
                                    <button className="btn btn-primary rounded-pill">Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* //Services Section */}
            {/* Product Section */}
            <section className='our_product py-5'>
                <div className="container">
                    <h1 className='text-center text-light py-3'>Our Product</h1>
                    <div className="row mt-5">
                        <div className="col-md-4 mt-3 ">
                            <div className="card p-4">
                                <img src="assets/img/school_software.gif" alt="" width={300} className='rounded-pill img-fluid' />
                                <h3 className='text-center'>Coaching Management Software</h3>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card p-4">
                                <img src="assets/img/Garument_software.gif" alt="" width={300} className='rounded-pill img-fluid' />
                                <h3 className='text-center'>Garment Management Software</h3>
                            </div>
                        </div>
                        <div className="col-md-4  mt-3">
                            <div className="card p-4">
                                <img src="assets/img/ecom.gif" alt="" width={300} className='rounded-pill img-fluid' />
                                <h3 className='text-center'>E-Commerce Management Software</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Product Section // */}
            {/* Why Choose Us Section*/}
            <section className="w3l-whychooseus py-5" id="about1">
                <div className="container py-lg-5 py-md-4 py-2">
                    <h3 className="w3l-title-main mb-5 text-center">
                        The Creative Features, we provide <br /> to our clients
                    </h3>
                    <div className="row align-items-center">
                        <div className="col-lg-7 pe-lg-5 align-center">
                            <h1 className="w3l-title-main1 mb-5">Our Features</h1>

                            <p className="mt-3">
                                "Our product is designed to streamline your operations and boost efficiency, empowering your business to reach new heights. With innovative features and seamless integration, we provide the tools you need to succeed in today's competitive market."
                            </p>
                            <div className="row two-grids mt-5">
                                <div className="col-sm-6 grids_info d-flex">
                                    <i className="fas fa-chalkboard-teacher" />
                                    <div className="detail ms-3">
                                        <h4 className="w3l-subtitle">Online Service</h4>
                                        <p>
                                            Our team ensures seamless integration and management of online platforms for optimal performance.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 grids_info d-flex mt-sm-0 mt-4">
                                    <i className="fas fa-lightbulb" />
                                    <div className="detail ms-3">
                                        <h4 className="w3l-subtitle"> Creative Idea</h4>
                                        <p>
                                            Transform ideas into impactful digital experiences that captivate and inspire.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 grids_info d-flex mt-md-5 mt-4">
                                    <i className="fas fa-headset" />
                                    <div className="detail ms-3">
                                        <h4 className="w3l-subtitle">Unlimited Support</h4>
                                        <p>
                                            Our dedicated team is available 24/7 to address any issues and keep your operations uninterrupted.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 grids_info d-flex mt-md-5 mt-4">
                                    <i className="fab fa-servicestack" />
                                    <div className="detail ms-3">
                                        <h4 className="w3l-subtitle">Digital Services</h4>
                                        <p>
                                            We help businesses leverage technology to enhance their digital footprint and achieve growth.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-lg-0 mt-5 position-relative">
                            {/* <img src="assets/images/about1.jpg" alt="" className="img-fluid" /> */}
                            <img src="assets/img/benifit-img.png" alt="" className="img-fluid " />
                            <img src="assets/img/arj_Logo.png" className='tp-benifit-right-shape-1' alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* //Why Choose Us Section*/}

            {/*/testimonials*/}
            <section className="w3l-clients py-5" id="testimonials">
                <div className="container py-sm-5 py-2">
                    <div className="text-center mb-sm-5 mb-4">
                        <h6 className="w3l-title-small mb-2">Testimonials</h6>
                        <h3 className="w3l-title-main mb-2">What people say about us</h3>
                    </div>
                    <div className="w3-testimonial-grids align-items-center pt-md-4">
                        <div className="w3-testimonial-content-top">
                            <div id="owl-demo1" className="owl-carousel owl-theme">
                                <div className="item">
                                    <div className="testimonial-content">
                                        <div className="test-img">
                                            <img
                                                src="assets/img/vansh.png"
                                                className="img-fluid"
                                                alt="client-img"
                                            />
                                        </div>
                                        <div className="testimonial">
                                            <blockquote>
                                                <q>
                                                    "ARJ Technologies’ software has made our day-to-day operations much smoother. From managing customer orders to handling returns and exchanges, everything is organized and streamlined. The interface is user-friendly, which makes training new staff easy. Plus, the support from ARJ Technologies is exceptional – they’re always quick to help whenever we need assistance."


                                                </q>
                                            </blockquote>
                                            <div className="testi-des">
                                                <div className="peopl align-self">
                                                    <h3>FIXA MANS</h3>
                                                    <p className="indentity">Vansh Kumawat</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-content">
                                        <div className="test-img">
                                            <img
                                                src="assets/images/team4.jpg"
                                                className="img-fluid"
                                                alt="client-img"
                                            />
                                        </div>
                                        <div className="testimonial">
                                            <blockquote>
                                                <q>
                                                    "This software has simplified our entire coaching center's management. We were struggling with manual record-keeping, but now ARJ Technologies has automated everything. The reporting feature is fantastic, and the real-time updates keep us in control. The flexibility of the platform lets us customize it to fit our coaching style, which is great!"
                                                </q>
                                            </blockquote>
                                            <div className="testi-des">
                                                <div className="peopl align-self">
                                                    <h3>Utthan Coaching </h3>
                                                    <p className="indentity">Banwari ji</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-content">
                                        <div className="test-img">
                                            <img
                                                src="assets/images/team3.jpg"
                                                className="img-fluid"
                                                alt="client-img"
                                            />
                                        </div>
                                        <div className="testimonial">
                                            <blockquote>
                                                <q>
                                                    "ARJ Technologies' school management software has been instrumental in helping us digitize our operations. From student admissions to exam results, everything is organized seamlessly. We particularly love the real-time updates and how easy it is to keep parents informed. It’s helped us improve our overall efficiency and allowed us to focus more on the students."


                                                </q>
                                            </blockquote>
                                            <div className="testi-des">
                                                <div className="peopl align-self">
                                                    <h3>Arj Institute Of Technology</h3>
                                                    <p className="indentity">Aakesh Saini</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-content">
                                        <div className="test-img">
                                            <img
                                                src="assets/images/team4.jpg"
                                                className="img-fluid"
                                                alt="client-img"
                                            />
                                        </div>
                                        <div className="testimonial">
                                            <blockquote>
                                                <q>
                                                    "Using ARJ Technologies' school management software has been a game-changer for us. The platform's user-friendly interface and powerful features have made managing student data, attendance, and fee records incredibly easy. Our communication with parents and students has improved significantly. It’s efficient, reliable, and tailored to our needs. Highly recommend it to any educational institution looking for a robust solution!"
                                                </q>
                                            </blockquote>
                                            <div className="testi-des">
                                                <div className="peopl align-self">
                                                    <h3>Saransh School Chomu</h3>
                                                    <p className="indentity">Mr. Suni Lal Kumawat</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* //testimonials */}
            {/* technology Section */}
            <section className="new_tech_sec">
                <div className="container">
                    <h1 className='text-center'>Our Latest Technologies</h1>

                    <div className="row mt-3 mb-3">
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/react.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/android_226770.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/app-store_5977575.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/react_1183672.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/php.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/laravel.995x1024.png" alt="" width={65} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-3">
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/node-js.909x1024.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/java_919854.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/python_1822899.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/mysql_919836.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/amazon_13170782.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/git_4494748.png" alt="" width={65} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-3">
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/html-5_5968267.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/css-3_5968242.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech" >
                                <img src="assets/img/bs5.png" alt="" width={85} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/photoshop_5968520.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/illustrator_5968472.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/js_5968292.png" alt="" width={65} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* //technology Section */}
            {/* call block */}
            <section className="w3l-call-to-action-6">
                <div className="container py-5">
                    <div className="d-lg-flex align-items-center justify-content-between">
                        <div className="left-content-call">
                            <span className="mb-2 text-italic">
                                "Empowering Your Vision with Innovative Solutions"
                            </span>
                            <h3 className="w3l-title-main text-white">Enhancing your success</h3>
                        </div>
                        <div className="right-content-call mt-lg-0 mt-4">
                            <ul className="buttons">
                                <li className="phone-sec me-lg-4">
                                    <i className="fas fa-phone-volume" />
                                    <a className="call-style-w3" href="tel:+917733095696">
                                        +917733095696
                                    </a>
                                </li>
                                <li>
                                    <Link
                                        to={'/contact'}
                                        className="btn btn-style transparent-btn mt-lg-0 mt-3"
                                    >
                                        Get Free Quote
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //call block */}
            <Footer />
        </>

    )
}
