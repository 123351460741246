import React from 'react'
import { Link } from 'react-router-dom'


export default function Header() {
    return (
        <>
            {/* top header */}
            <section className="w3l-top-header">
                <div className="container">
                    <div className="top-content-w3ls d-flex align-items-center justify-content-between">
                        <div className="top-headers">
                            <ul>
                                <li>
                                    <i className="fa fa-map-marker-alt" />
                                    <a href="#address">MahaLaxmi Tower, Thana Mod, Chomu, Rajasthan 303702</a>
                                </li>
                                <li>
                                    <i className="fa fa-phone" />
                                    <a href="tel:+91 7733095696">+91 7733095696</a>
                                </li>
                                <li>
                                    <i className="fa fa-envelope" />
                                    <a href="mailto:arjinstitute@gmail.com">arjinstitute@gmail.com</a>
                                </li>
                            </ul>
                        </div>
                        <div className="top-headers top-headers-2">
                            <ul>
                                <li>
                                    <a href="#facebook">
                                        <span className="fab fa-facebook-f" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#twitter">
                                        <span className="fab fa-twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#instagram">
                                        <span className="fab fa-instagram" />
                                    </a>
                                </li>
                                <li className=" mr-0">
                                    <a href="#linkedin">
                                        <span className="fab fa-linkedin-in" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //top header */}
            {/*header*/}
            <header id="site-header" className="fixed-top">
                <div className="container">
                    <nav className="navbar navbar-expand-lg stroke">
                        <h1>
                            <a className="navbar-brand" href="/">
                                <img src="assets/img/arj_Logo.png" alt="" className='w-50' />
                            </a>
                        </h1>
                        {/* if logo is image enable this   
      <a class="navbar-brand" href="#index.html">
          <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
      </a> */}
                        <button
                            className="navbar-toggler collapsed bg-gradient"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarTogglerDemo02"
                            aria-controls="navbarTogglerDemo02"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon fa icon-expand fa-bars" />
                            <span className="navbar-toggler-icon fa icon-close fa-times" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul className="navbar-nav ms-lg-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">
                                        Home <span className="sr-only">(current)</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/About">
                                        About
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/Services">
                                        Services
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/Contact">
                                        Contact
                                    </Link>
                                </li>
                              
                            </ul>
                        </div>

                    </nav>
                </div>
            </header>
            {/*//header*/}

        </>

    )
}
