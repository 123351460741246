import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <>
            {/* footer block */}
            <footer className="w3l-footer-29-main">
                <div className="footer-29 pt-5 pb-4">
                    <div className="container pt-md-4">
                        <div className="row footer-top-29">
                            <div className="col-lg-4 col-md-6 col-12 footer-list-29 text-center text-md-start">
                                <h6 className="footer-title-29">Contact Info</h6>
                                <p className="mb-2 pe-xl-5">
                                    Address: MahaLaxmi Tower, Dholi Mandi Road, Thana-Mod, Chomu, Jaipur, Rajasthan 303702.
                                </p>
                                <p className="mb-2">
                                    Phone Number: <a href="tel:+917733095696">+917733095696</a>
                                </p>
                                <p className="mb-2">
                                    Email: <a href="mailto:arjtechnologies@gmail.com">arjtechnologies@gmail.com</a>
                                </p>
                                <p className="mb-2">
                                    Support: <a href="mailto:support@example.com">support@example.com</a>
                                </p>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 footer-list-29 mt-md-0 mt-4 text-center">
                                <h6 className="footer-title-29">Quick Links</h6>
                                <ul>
                                    <li><Link to="/About">About Company</Link></li>
                                    <li><Link to="/About">About Our Team</Link></li>
                                    <li><Link to="/Services">Our Services</Link></li>
                                    <li><Link to="/Contact">Contact Us</Link></li>
                                    <li><Link to="/">Our Product</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 footer-list-29 mt-lg-0 mt-4 ps-lg-5 text-center text-md-start">
                                <h6 className="footer-title-29">Subscribe</h6>
                                <form action="#" className="subscribe d-flex justify-content-center justify-content-md-start" method="">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email Address"
                                        required=""
                                        className="me-2"
                                    />
                                    <button className="button-style">
                                        <span className="fa fa-paper-plane" aria-hidden="true" />
                                    </button>
                                </form>
                                <p className="mt-4">
                                    Subscribe to our mailing list and get updates to your email inbox.
                                </p>
                            </div>
                        </div>
                        {/* copyright */}
                        <p className="copy-footer-29 text-center mt-5 pb-2">
                            © 2024 ARJ. All rights reserved. Developed by{" "}
                            <a href="/" target="_blank">
                                ARJ Technologies
                            </a>
                        </p>
                        {/* //copyright */}
                    </div>
                </div>
            </footer>

            {/* //footer block */}
            {/* Js scripts */}
            {/* move top */}
            <button onclick="topFunction()" id="movetop" title="Go to top">
                <span className="fas fa-level-up-alt" aria-hidden="true" />
            </button>
            <section className="whatsapp_icon">
                <a href="https://wa.me/917733095696" target="blank">
                    <img src="assets/img/whats_app.png" alt="Awesome Image" />
                </a>
            </section>

        </>

    )
}
